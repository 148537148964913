import {User} from '@hconnect/apiclient'
import {FeaturesCheck} from '@hconnect/common/components/FeaturesCheck'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {getRouteByPath} from '@hconnect/uikit'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Redirect, Route} from 'react-router'
import {useHistory, useLocation} from 'react-router-dom'

import {loginFlow, api} from './App.global'
import {useRecoverScrollPosition} from './Hooks/useScroll'
import {Layout} from './Molecules/Layout'
import {CustomerStateType} from './Organisms/Customers'
import {Features, useFeaturesState} from './Organisms/Features'
import Certificate from './Pages/Certificate'
import GenerateCertificate from './Pages/Certificate/Cement/LettersOfCertification/GenerateCertificate'
import ManageCertificates from './Pages/Certificate/Cement/LettersOfCertification/ManageCertificates/ManageCertificates'
import Error404 from './Pages/Error/Error404/Error404'
import Invoice from './Pages/Finance'
import InvoiceDetail from './Pages/Finance/Invoices/InvoiceDetail'
import PaymentConfirmation from './Pages/Finance/PaymentConfirmation'
import {CreateInvitePage} from './Pages/Invitation/Create/CreateInvitePage'
import {InvitationRoute} from './Pages/Invitation/Overview/InvitationsRoute'
import {ReceiveInvitePage} from './Pages/Invitation/Receive/ReceiveInvitePage'
import Disclaimer from './Pages/Legal/Disclaimer'
import Imprint from './Pages/Legal/Imprint'
import Privacy from './Pages/Legal/Privacy'
import SalesConditions from './Pages/Legal/SalesConditions'
import Terms from './Pages/Legal/Terms'
import NotificationSettings from './Pages/NotificationSettings'
import Order from './Pages/Order'
import OrderDetails from './Pages/Order/OrderDetails'
import {BulkCementCollectOrderIntake} from './Pages/OrderIntake/BulkCementCollectOrderIntake'
import {BulkCementDeliveryOrderIntake} from './Pages/OrderIntake/BulkCementDeliveryOrderIntake'
import Overview from './Pages/Overview'
import {C60PartnerPage} from './Pages/Partners/layouts'
import {PartnersRoute} from './Pages/Partners/PartnersRoute'
import RequestForQuote from './Pages/RequestForQuote'
import {Version} from './Pages/Version'
import {AppState} from './Root.store'

// eslint-disable-next-line complexity
export const App = (props) => {
  const history = useHistory()
  const location = useLocation()
  const {getFeature} = useFeaturesState()
  const {customers, isFetching} = useSelector<AppState, CustomerStateType>(
    (state) => state.customers
  )
  const user = useSelector<AppState, User | null>((state) => state.userProfile.userProfile)
  const {user_id, country, hasRoles, isInternal} = user || {}

  useEffect(() => {
    if (props.loggedIn && !isFetching && customers && customers.length > 0 && !isInternal) {
      trackEvent('hubUserLoggedIn', {
        product: 'hub',
        userId: user_id,
        userCountry: country,
        userRoles: hasRoles,
        customerIds: customers.map(({customerId}) => customerId)
      })
    }
  }, [isFetching, customers])

  useRecoverScrollPosition(history, location)

  const useHaulierView = getFeature('UseHaulierView')
  const defaultRoute = useHaulierView ? '/orders' : '/overview'

  return (
    <Layout
      logout={() => loginFlow.startLogoutProcess()}
      location={location}
      history={history}
      isResponsive
    >
      <Order location={location} />
      <OrderDetails location={location} />
      {!useHaulierView && (
        <>
          <Overview location={location} />
          <Features name="OrderIntakeRequestForQuote">
            <RequestForQuote location={location} />
          </Features>
          <NotificationSettings location={location} />
          <Features name="Finance">
            <Invoice location={location} />
            <InvoiceDetail location={location} />
            <PaymentConfirmation location={location} />
          </Features>
          <Features names={['TestReportsMillCertificates', 'RMCTestReports']}>
            <Certificate location={location} />
            <GenerateCertificate />
            <ManageCertificates />
          </Features>
          <Privacy location={location} />
          <Terms location={location} />
          <Disclaimer location={location} />
          <SalesConditions location={location} />
          <Imprint location={location} />
          <Route path="/version" exact render={() => <Version />} />
          <Route path="/login" exact render={() => <Redirect to={defaultRoute} />} />
          <Route
            path="/bulkCementDelivery"
            exact
            render={() => <BulkCementDeliveryOrderIntake />}
          />
          <Route
            path="/bulkCementCollection"
            exact
            render={() => <BulkCementCollectOrderIntake />}
          />
          <FeaturesCheck api={api} name={['SalesAgentInviteUser', 'BusinessOwnerInviteUser']}>
            <InvitationRoute location={location} />
            <Route path="/createInvite" exact render={() => <CreateInvitePage />} />
          </FeaturesCheck>
          <Route
            path="/receiveInvite/:countryId/:roleRequestId"
            exact
            render={() => <ReceiveInvitePage />}
          />
          <Features name="Partners">
            <PartnersRoute />
            <Route path="/partners/c60" exact render={() => <C60PartnerPage />} />
          </Features>
        </>
      )}

      <Route path="/" exact render={() => <Redirect to={defaultRoute} />} />
      {useHaulierView && (
        <Route path="/overview" exact render={() => <Redirect to={defaultRoute} />} />
      )}
      {useHaulierView &&
        !location.pathname.startsWith('/orders') &&
        location.pathname !== '/version' && <Error404 />}
      {!useHaulierView &&
        getRouteByPath(location.pathname) === null &&
        !location.pathname.startsWith('/receiveInvite') &&
        !location.pathname.startsWith('/createInvite') &&
        !location.pathname.startsWith('/certificates') &&
        !location.pathname.startsWith('/downloadRequests') &&
        !location.pathname.startsWith('/finance/payments') &&
        !location.pathname.startsWith('/bulkCementDelivery') &&
        !location.pathname.startsWith('/bulkCementCollection') &&
        !location.pathname.startsWith('/requestForQuote') &&
        !location.pathname.startsWith('/notificationSettings') &&
        location.pathname !== '/version' && <Error404 />}
    </Layout>
  )
}
